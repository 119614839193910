@import "locomotive-scroll/dist/locomotive-scroll.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin-slow {
    0% {
        transform: rotate(0deg) translateX(120px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(120px) rotate(-360deg);
    }
}

.animate-spin-slow {
    animation: spin-slow 10s linear infinite;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Add these to your CSS file */

.bg-salmon-500 {
    background-color: #FA8072;
}

.bg-turquoise-500 {
    background-color: #40E0D0;
}

.clip-polygon {
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.dropdown-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.1s ease, opacity 0.1s ease;
}
.dropdown-enter-active {
    max-height: 200px; /* Adjust based on content size */
    opacity: 1;
}
.dropdown-exit {
    max-height: 200px;
    opacity: 1;
    overflow: hidden;
    transition: max-height 0.1s ease, opacity 0.1s ease;
}
.dropdown-exit-active {
    max-height: 0;
    opacity: 0;
}

